.cta {
	text-align: right;
	overflow: hidden;

	&--left {
		text-align: left;
		padding-right: 4em;

		.cta__link {
			&:after {
				right: -1rem;
				left: -3rem;
			}
			&:hover {
				transform: translateX(1rem);
			}
		}
	}

	&__link {
		display: inline-block;
		@include font-size($s-gamma);
		text-transform: uppercase;
		font-weight: $w-bold;
		color: $c-white;
		padding: 1em 3em;
		position: relative;
		z-index: 1;
		transition: transform $a;
		&:after {
			content: "";
			display: block;
			background: $brand-primary;
			position: absolute;
			top: 0;
			left: -1rem;
			right: -3rem;
			bottom: 0;
			z-index: -1;
			transform: skew($angle);
			@include mq($mq-small) {
				left: 0;
				right: 0;
				transform: skew(0);
			}
		}
		&:hover {
			transform: translateX(-1rem);
		}
		@include mq($mq-small) {
			text-align: center;
			display: block;
			padding: 1em;
			@include font-size($s-delta);
			&:hover {
				transform: translateX(0);
			}
		}
	}
}
