.hamburger {
	width: 16px;
  height: 2.4px;
	display: block;
	position: relative;
	background: currentColor;
	margin: 0.8em 0;
	transition: all $a;
	color: #fff;
	transition: color .4s ease;
	&:before, &:after {
		content: "";
		display: block;
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background: currentColor;
		transition: all $a;
	}
	&:before {
		transform: translateY(-6px);
	}
	&:after {
		transform: translateY(6px);
	}
	.top__menu.is-open ~ .top__toggle & {
		background: transparent;
		color: #fff!important;
		&:before {
			transform: rotate(45deg);
		}
		&:after {
			transform: rotate(-45deg);
		}
	}
}
