.logos {

  @include clearfix;
  margin-bottom: 6em;

  &__logo {
    float: left;
    width: (100% / 8);
    padding: 0 25px;

    @include mq($mq-medium) {
      width: (100% / 4);
      padding: 10px;
    }

    @include mq($mq-small) {
      width: (100% / 2);
    }

  }

  .logo {
    padding-bottom: 50%;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }

}
