.stats {
  text-align: center;
}

.stat {

  display: inline-block;
  vertical-align: top;
  padding: 0 20px;
  width: 30%;

  &__icon {
    max-width: 150px;
    margin: 0 auto 10px;
    padding-bottom: 66%;
    background-size: contain;
    background-position: bottom center;
    background-repeat: no-repeat;
  }

  &__figure {
    @include font-size($s-alpha);
    line-height: 1em;
    color: $brand-primary;
    font-weight: bold;
  }

  &__caption {
    @include font-size(12);
    text-transform: uppercase;
    max-width: 160px;
    margin: 5px auto 0;
  }

}
