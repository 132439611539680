.slide {

 

  &__image {
    position: absolute;
    top: -25%;
    bottom: -5%;
    left: 0;
    right: 0;
    width: 100%;
    height: 130%;

    background-position: center;
    background-size: cover;
  }

  &--full {
    overflow: hidden;
    height:380px;
  }

  &--dark {
    background: black;

    &:after {
      content: '';
      display: block;
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      height: 200px;
      background: -moz-linear-gradient(top,  rgba(255,255,255,0) 0%, rgba(0,0,0,0.6) 100%);
      background: -webkit-linear-gradient(top,  rgba(255,255,255,0) 0%,rgba(0,0,0,0.6) 100%);
      background: linear-gradient(to bottom,  rgba(255,255,255,0) 0%,rgba(0,0,0,0.6) 100%);
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#99000000',GradientType=0 );
      
      
  
    }

  }

  &--red {
    background-color: $brand-primary;
  }



}



/* Arrows */

.slick-next,
.slick-prev {
  position: absolute;
  display: block;
  height: 100%;
      width: 30%;
  line-height: 0;
  font-size: 0;

  cursor: pointer;
  color: $brand-secondary-darker;
  background: none;
  top: 0;
  border-radius: 0;
  border: none;
  outline: none;
  z-index: 5;
  transition: all 0.3s ease;
  @include media-breakpoint-down(sm) {
    display: none!important;
  }

  &:hover {
    outline: none;
    border: none;

    &:before {
      opacity: $slick-opacity-on-hover;
      color: $brand-primary-darker;
    }
  }

  &.focus,
  &:focus {
    outline: none;
    border: none;
  }

  &.slick-disabled:before {
    opacity: $slick-opacity-not-active;
  }

  &:before {
    font-family: $slick-font-family;
    font-size: 50px;
    line-height: 1;
    color: $brand-secondary-darker;
    opacity: $slick-opacity-default;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
}

.slick-prev {
  left: -30px;
  text-align: left;


  [dir="rtl"] & {
    left: auto;
    right: 50px;
  }

  &:before {
    content: $slick-prev-character;

    [dir="rtl"] & {
      content: $slick-next-character;
    }
  }
}

.slick-next {
  right: -30px;
  text-align: right;


  [dir="rtl"] & {
    left: 50px;
    right: auto;
  }

  &:before {
    content: $slick-next-character;

    [dir="rtl"] & {
      content: $slick-prev-character;
    }
  }
}
/* Dots */
.slick-dots {
  position: absolute;
  bottom: 10px;
  list-style: none;
  display: block;
  text-align: right;
  right: 20px;
  padding: 0;
  margin: 0;
  width: 100%;
  z-index: 20;
  @include media-breakpoint-up(md) {
    bottom: 20px;
  }

  li {
    position: relative;
    display: inline-block;
    height: 10px;
    width: 10px;
    margin: 0 5px;
    padding: 0;
    cursor: pointer;

    button {
      border: 0;
      background: transparent;
      display: block;
      height: 10px;
      width: 10px;
      outline: none;
      line-height: 0;
      font-size: 0;
      color: transparent;
      padding: 3px;
      cursor: pointer;

      &:focus,
      &:hover {
        outline: none;

        &:before {
          opacity: $slick-opacity-on-hover;
        }
      }

      &:before {
        position: absolute;
        top: 0;
        left: 0;
        content: $slick-dot-character;
        width: 10px;
        height: 10px;
        font-family: $slick-font-family;
        font-size: $slick-dot-size;
        line-height: 20px;
        text-align: center;
        color: $brand-primary-lighter;
        opacity: $slick-opacity-not-active;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        transition: all 0.2s ease;
      }
    }

    &.slick-active button:before {
      color: $brand-primary;
      opacity: $slick-opacity-default;
    }
  }
}

.slick-slider {
  .slide {
    opacity: 0;
    transition: opacity 15s ease;
  }
}

.slick-initialized .slide {
  opacity: 1;
}
// Individual sliders
.multiple-items   {
  padding: 10px;
  overflow: visible;
  .slick-slide {
    padding: 20px;
  }
  img {
    display: block;
    max-width: 100%;
    height: 120px!important;
    width: auto!important;
  }

  .slick-prev {
    left: -60px;
    text-align: left;
  }

  .slick-next {
    right: -60px;
    text-align: right;
  }

}

.multiple-items.group .slick-slide {
  padding: 50px 10px 10px;
  overflow: hidden;

  img {
    display: block;
    max-width: 100%;
    height: auto;
  }
}

.content-block .slick-slide {
  padding: 20px 10px 10px;
  overflow: hidden;

  img {
    display: block;
    max-width: 100%;
    height: auto;
  }
}

.slider-card {
  .card-body {
    padding: 0 0 0.75rem;
  }

  .card-title {
    line-height: 1.4;
    margin: 0.9rem 0;
    @include media-breakpoint-up(xs) {
      font-size: 0.8em;
      min-height: 110px;
      margin: 0.2rem 0;
    }
    @include media-breakpoint-up(sm) {
      min-height: 90px;
      margin: 0.4rem 0;
    }
    @include media-breakpoint-up(md) {
      min-height: 60px;
    }
    @include media-breakpoint-up(lg) {
      font-size: 0.8em;
      min-height: 70px;
    }

    a {
      color: $brand-secondary-darker;
    }
  }
}

.featuredslider {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover; //  background-attachment: fixed;

  .slide {
    height: 70vh;
    margin-top: -30px;
    margin-bottom: -5px;
    // height: calc(100vh - #{$navbar-height} - 228px);
    min-height: 400px;
    @extend .featuredslider;

    .display-2 {
      color: #fff;
      font-weight: 200;
      font-size: 3.2em;
      text-shadow: 1px 1px 2px #00000080;
      max-width: 600px;

    }

    &:before {
      background-color: rgba(0,0,0,0.35);
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      display: block;
    }
  }

  .slick-next,
  .slick-prev {
    position: absolute;
    display: block;
    height: 60px;
    width: 60px;
    line-height: 0;
    font-size: 0;
    cursor: pointer;
    background-color: transparent;
    color: #fff;
    top: 40%;
    border: none;
    outline: none;
    z-index: 5;
    transition: all 0.1s ease;

    &:hover {
      outline: none;
      background-color: transparent;
      border-color: #fff;
      color: #fff;

      &:before {
        opacity: $slick-opacity-on-hover;
      }
    }

    &.focus,
    &:focus {}

    &.slick-disabled:before {
      opacity: $slick-opacity-not-active;
    }

    &:before {
      font-family: $slick-font-family;
      font-size: 20px;
      line-height: 0.7;
      color: $slick-arrow-color;
      opacity: $slick-opacity-default;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      font-weight: 900;
    }
  }

  .slick-prev {
    padding-left: 10px;
    left: 5%;

    &:hover {
      padding-left: 0;
    }
  }

  .slick-next {
    padding-right: 10px;
    right: 5%;

    &:hover {
      padding-right: 0;
    }
  }
}

.home .featuredslider {
  .slide {
    height: calc(100vh + 60px);

      margin-top: -30px;
      margin-bottom: -30px;

    &:before {
      content: none;
    }
  }
}

.testimonials .container {
  max-width: 1300px;
  overflow: hidden;

  .slick-list {
    @include media-breakpoint-up(lg) {
      padding: 100px;
    }
  }
}

.ctaslider {


    h4 {
      color: #fff;
    }

  .slick-next,
  .slick-prev {
    &:before {
        color: $brand-primary;
      }
      }
  .slick-dots {

    bottom: -20px;
    li {
      button {
        &:before {
          color: #fff;
        }
      }
      &.slick-active button:before {
        color: #fff;
      }
    }
  }
}
