@keyframes bounce {
	0%, 20%, 50%, 80%, 100% {
		transform: translateY(0);
	}
	40% {
		transform: translateY(-30px);
	}
	60% {
		transform: translateY(-15px);
	}
}

.hero {
	display: flex;
	overflow: hidden;
	position: relative;
	min-height: calc(100vh - 90px);
	&__brand {
		flex: 1;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		margin-right: 10%;
		@include font-size($s-beta);
	}
	&__logo {
		width: 555px;
		margin-bottom: 0.5em;
	}
	&__sectors {
		flex: 1.25;
		display: flex;
		transform: skew($angle) translateZ(0);
		transform-origin: center top;
	}
	&__arrow {
		position: absolute;
		bottom: 0;
		right: 0;
		width: 12%;
		bottom: 2em;
		animation: bounce 2s infinite;
		svg {
			fill: $brand-primary;
			width: 45px;
			height: 20px;
			margin: 0 auto;
		}
	}
	@include mq($mq-xxlarge) {
		&__brand {
			@include font-size($s-gamma);
		}
		&__logo {
			width: 400px;
		}
		&__sectors {
			flex: 2;
		}
		&__arrow {
			width: 16%;
		}
	}
	@include mq($mq-xlarge) {
		&__brand {
			@include font-size($s-delta);
		}
		&__logo {
			width: 260px;
		}
		&__sectors {
			flex: 2.5;
		}
	}
	@include mq($mq-large) {
		&__brand {
			display: none;
		}
		&__sectors {
			flex: 1;
			transform: none;
		}
		&__arrow {
			display: none;
		}
	}

	@include mq($mq-small) {
		min-height: calc(100vh - 62px);
		&__sectors {
			flex-direction: column;
		}
	}
}

.hero-sector {
	background: $c-mid;
	flex: 1;
	color: $c-white;
	border-left: 8px solid $c-white;
	transition: all $a;
	overflow: hidden;
	position: relative;
	&__background {
		content: "";
		display: block;
		background: center center no-repeat;
		background-size: cover;
		background-image: inherit;
		background-color: inherit;
		background-blend-mode: multiply;
		position: absolute;
		top: 0;
		left: -10vw;
		right: -10vw;
		bottom: 0;
		transform: skew(-$angle) translateZ(0);
		opacity: 0.6;
	}
	&__label {
		transition: all $a;
		position: absolute;
		left: 0;
		right: 0;
		bottom: 4em;
		text-align: center;
		transform: skew(-$angle);
		transform-origin: top center;
		.hero__sectors:hover & {
			opacity: 0;
		}
	}
	&__content {
		opacity: 0;
		transition: all;
		max-width: 400px;
		width: (2/3)*100%;
		position: absolute;
		left: 50%;
		top: 50%;
		transform: skew(-$angle) translate(-50%, -50%);
		transform-origin: top center;
		.divider {
			border-color: currentColor;
		}
	}
	&:hover {
		flex: 4;
		background: $brand-primary;
		.hero-sector__content {
			opacity: 1;
			transition-delay: 0.2s;
			transition-duration: 0.2s;
		}
	}
	@include mq($mq-large) {
		&:first-child {
			border-left: 0;
		}
		&__background, &__label {
			transform: none;
		}
		&__background {
			left: 0;
			right: 0;
		}
		&__content {
			transform: translate(-50%, -50%);
			width: 80%;
			max-width: none;
		}
	}
	@include mq($mq-small) {
		border-left: 0;
		border-top: 8px solid $c-white;
		&:first-child {
			border-top: 0;
		}
	}
}
