/* Zoom-out lightbox effect */
.mfp-zoom-in {

  /* start state */
  .mfp-with-anim {
    opacity: 0;
    transition: all 0.2s ease-in-out;
    transform: scale(0.8);
  }

  &.mfp-bg {
    opacity: 0;
	  transition: all 0.3s ease-out;
  }

  /* animate in */
  &.mfp-ready {
    .mfp-with-anim {
      opacity: 1;
      transform: scale(1);
    }
    &.mfp-bg {
      opacity: 0.8;
    }
  }

  /* animate out */
  &.mfp-removing {

    .mfp-with-anim {
      transform: scale(0.8);
      opacity: 0;
    }
    &.mfp-bg {
      opacity: 0;
    }

  }

}

.mfp-zoom-out {

  /* start state */
  .mfp-with-anim {
    opacity: 0;
    transition: all 0.2s ease-in-out;
    transform: scale(1.3);
  }

  &.mfp-bg {
    opacity: 0;
	  transition: all 0.2s ease-out;
  }

  /* animate in */
  &.mfp-ready {
    .mfp-with-anim {
      opacity: 1;
      transform: scale(1);
    }
    &.mfp-bg {
      opacity: 0.8;
    }
  }

  /* animate out */
  &.mfp-removing {

    .mfp-with-anim {
      transform: scale(1.3);
      opacity: 0;
    }
    &.mfp-bg {
      opacity: 0;
    }

  }

}

.mfp-with-fade {
  // before-open state
  .mfp-content,
  &.mfp-bg {
    opacity: 0;
    transition: opacity .5s ease-out;
  }
  // open state
  &.mfp-ready {
    .mfp-content {
     opacity: 1;
    }
    &.mfp-bg {
      opacity: 0.6; // background opacity
    }
  }

  // closed state
  &.mfp-removing {
    &.mfp-bg {
      opacity: 0;
    }
  }
}

.popup {
  position: relative;
  width: auto;
  max-width: 80%;
  margin: 20px auto;

}

.form-popup {
  position: relative;
  width: 660px;
  max-width: 100%;
  border-radius: 26px;
  margin: 20px auto;
  padding: 2em 4em 3em;

  @include media-breakpoint-down(sm) {
      width: 100%;
      max-width: 100%;
      border-radius: 0;
      margin: 0;
      padding: 2em 1em;
    }

}

.mfp-ready.mfp-bg {
  opacity: 1!important;
}

.mfp-bg {
    background: rgba(255,255,255,0.7);
}

.mfp-close, .mfp-close:active {
    width: 88px;
    height: 88px;
    line-height: 88px;
    opacity: 0.8;
    font-size: 48px;
    right: -30px;
    top: -70px;
}

.mfp-iframe-holder .mfp-close {
    top: -70px;
}

.mfp-iframe-holder .mfp-close, .mfp-image-holder .mfp-close {
  color: $brand-primary;
  right: 20px;
  top: 40px;
  font-size: 4em;
}


@keyframes fadeMe {
    0% {opacity: 0;}
    100% {opacity: 1;}
}

.mfp-open .mfp-content {
    animation-name: fadeMe;
    animation-duration: 1s;
    animation-fill-mode: both;
    -webkit-backface-visibility: hidden;
}

.form-popup  .mfp-close {
  color: #fff;
}

// class added by js during a modal popup

body.noscroll
{
    overflow-y:hidden!important;
    margin-right: 15px;
}

.mfp-title {
    text-align: left;
    line-height: 1.6em;
    color: #fff;
    word-wrap: break-word;
    padding-right: 36px;
    background: $brand-primary;
    width: 100%;
    padding: 10px 20px;
    margin-top: -100px;
    font-size: 0.8em;
}

.mfp-title:empty {
   display: none;
}


.tooltip-inner {
     max-width: 200px;
     padding: 3px 8px;
     color: #000;
     text-align: center;
     background-color: #fff;
     border-radius: 0;
 }
 .bs-tooltip-auto[x-placement^=bottom] .arrow:before, .bs-tooltip-bottom .arrow:before {
     border-bottom-color: #fff;
   }

   .bs-tooltip-auto[x-placement^=top] .arrow:before, .bs-tooltip-top .arrow:before {
       border-top-color: #fff;
     }
