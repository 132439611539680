.contact {
	display: flex;
	margin: 6em 0;
	@include mq($mq-small) {
		margin: 2em 0;
	}
	&__info {
		flex: 1;
		max-width: 640px;
		min-width: 520px;
		margin-right: 80px;
	}
	&__map {
		flex: 1;
	}
	&__form {
		padding-top: 1em;
	}
	&__split {
		display: flex;
		align-items: flex-start;
	}
	&__details {
		margin: 1em 0 0 0.5em;
		padding-right: 2em;
		border-left: 2px solid $brand-primary;
		padding-left: 1em;
		.social-links {
			margin-top: 2em;
			color: $c-white;
			a {
				background: $c-dark;
			}
		}
	}
	&__fields {
		flex: 1;
	}
	&__submit {
		display: flex;
		align-items: center;
		.form-field {
			margin-bottom: 0;
			flex: 1;
		}
		.form-submit {
			margin-left: 1em;
		}
	}

	@include mq($mq-xlarge) {
		&__info {
			margin-right: 20px;
		}
	}
	@include mq($mq-medium) {
		flex-direction: column;
		&__info {
			max-width: none;
			margin-right: 0;
			margin-bottom: 2em;
		}
		&__map {
			height: 400px;
		}
	}
	@include mq($mq-small) {
		&__info {
			min-width: 0;
		}
		&__split {
			flex-direction: column;
			align-items: stretch;
		}
		&__details {
			padding-right: 0;
			display: flex;
			justify-content: space-between;
			align-items: flex-start;
			margin-bottom: 1em;
			margin-left: 0;
			.social-links {
				margin-top: 0;
			}
		}
	}
	@include mq($mq-xsmall) {
		&__details {
			flex-direction: column;
		}
		&__map {
			height: 250px;
		}
	}
}

.wpcf7-form br { display: none; }
