.cta-footer {
	background-size: cover;
	background-repeat: no-repeat;
	position: relative;

	&:before {
		content: '';
		display: block;
		left: 0;
		right: 0;
		top: 0;
		bottom: 0;
		position: absolute;
		background: #ff6c6c;
		opacity: 0.4;
	}
}


.bottom {
	color: $c-white;
	@include font-size(14);
	line-height: 1;
	text-transform: uppercase;
	position: relative;

	&__contact {
		margin: 1.5em 0;
		li {
			display: inline-block;
			padding-right: 1.4em;
	  		  margin-right: 1.4em;
			color: $c-white;
			text-transform: capitalize;
			font-size: 0.9em;
				&:last-child {
					border: 0;
				}

				&.email-item {
					text-transform: lowercase;
					display: block;
				}
		}
	}

	@include mq($mq-xlarge) {
		&__contact li {
			padding-right: 1em;
			margin-right: 1em;
		}
	}
	@include mq($mq-large) {
		&__contact li {
			display: block;
			border: 0;
			padding-right: 0;
			margin-right: 0;
			margin-bottom: 0.5em;
		}
	}
	@include mq($mq-small) {
		text-align: center;
		font-size: 1rem;
		padding: 1.5em 0;
		.container {
			flex-direction: column;
			align-items: center;
		}

	}

	
}


.twitter_feeds {
.footer_twitter {
	svg {
		max-width: 20px;
    margin-right: 13px;
    display: inline-block;
	}
}
	#ctf .ctf-item {
	    padding: 0!important;
	}
	.ctf-author-box {
	    border-bottom: 1px solid!important;
	}
	#ctf.ctf-super-narrow .ctf-author-box {
	    min-height: 26px!important;
		}
	#ctf .ctf-author-name {
	    margin: 0!important;
		}
		#ctf .ctf-tweet-actions .ctf-like, #ctf .ctf-tweet-actions .ctf-reply, #ctf .ctf-tweet-actions .ctf-retweet {
			transition: all .2s ease;
		}

		#ctf .ctf-tweet-actions .ctf-like:hover, #ctf .ctf-tweet-actions .ctf-like:focus, #ctf .ctf-tweet-actions .ctf-reply:hover, #ctf .ctf-tweet-actions .ctf-reply:focus, #ctf .ctf-tweet-actions .ctf-retweet:hover, #ctf .ctf-tweet-actions .ctf-retweet:focus {
	    color: #fff !important;
			transform: scale(1.2);
	}
}
