.quote {
		p {
    text-align: left;
    font-size: 1.4em;
		}
		svg {
			max-width: 40px;
			margin: 0 0 10px 0;
		}
}


blockquote {
  margin: 0 auto;
  padding: 0 1em 0 2em;
  margin: .5em 5% .5em 0;
  text-align: right;;
  border-right: 2px solid $brand-secondary-darker;

  @include media-breakpoint-down(md) {
    float: right;
    text-align: left;
    border-right: none;
    border-left: 2px solid $brand-secondary-darker;
    padding: 0 2em 0 1em;
    margin: .5em 0 1em 5%;
  }
  @include media-breakpoint-up(lg) {
    max-width: 55%;
    float: left;
  }

  &:before {
    display: none;
  }
  &:not(:first-of-type) {
    margin-top: .5em;
  }
  p {
    font-size: 1.1em;
    margin: 0;
    @include media-breakpoint-up(lg) {
    font-size: 1.4em;
    }
    line-height: 1.3;
    font-family: 'Merriweather', serif;
      @include media-breakpoint-up(lg) {
    &:before {
    display: block;
    width: 15%;
    margin: 0 0 10px 85%;
    text-align: right;
    content: url("../images/quote.svg");
    }
  }
  }
  footer {
    margin-top: .5em;
    padding: 0;
    color: #777;
    font-size: 12pt;
    text-align: left;
    font-style: italic;
    &:before {
      content: '— ';
    }
  }
  &:nth-of-type(even) {
    p {
      &:before {
        margin: 0 85% 0 0 ;
      }
    }
    @include media-breakpoint-up(lg) {
      float: right;
      text-align: left;
      border-right: none;
      border-left: 2px solid $brand-secondary-darker;
      padding: 0 2em 0 1em;
      margin: .5em 0 0 5%;
    }
    footer {
      text-align: right;
      &:before {
        content: '';
      }
      &:after {
        content: ' —';
      }
    }
  }
}
