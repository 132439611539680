.top {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	padding: 1.77em 3em;
	display: flex;
	align-items: center;
	justify-content: flex-end;
	background: transparent;
	@include font-size($s-micro);
	font-weight: $w-semibold;
	text-transform: capitalize;
	z-index: 100;
	transition: all 0.5s ease;
	&__logo {
		flex: 1;
		font-size: 0;
		a {
			// background: $brand-primary;
			display: inline-block;
			padding: 8px;
		}
		svg {
			width: 120px;
			fill: $c-white;
			transition: fill .4s ease;
		}

	}
	.social-links {
		color: $c-white;
		margin-left: 2.5em;
		margin-top: .5em;
		@include mq($mq-small) {
			margin-left: 2em;
		}
	}
	&__menu {
		opacity: 0;
		position: fixed;
		top: 0;
		right: -200px;
		bottom: 0;
		z-index: 2;
		padding: 0 7em 0 7em;
		background: $brand-primary;
		color: $c-white;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		transition: all $a-slow;
		transform: translateX(140%);
		@include font-size($s-delta);
		&:after {
			//content: "";
			position: absolute;
			top: 0;
			right: 100%;
			z-index: -1;
			transition: transform $a-slow;
			width: 100%;
			height: 100vh;
			background: $brand-primary;
			transform: skew(-6deg) translateX(70%);

			@include mq(1350px) {
				width: 105%;
			}
		}
		.menu {
			display: flex;
			flex-direction: column;
			text-align: right;
		}
		.social-links {
			color: $brand-primary;
			margin-left: 0;
			margin-top: 2em;
			text-align: center;
			transition: fill .4s ease;
			a {
				background: $c-white;
			}
		}
		&.is-open {
			opacity: 1;
			transform: translateX(-200px);
		}
		.menu-item {
			display: block;
			a {
				display: inline-block;
				padding: 0.5em 1em;
				color: #fff;

     font-weight: 300
			}
			&:after {
				display: none;
			}
		}
	}

	&__phone {
		color: $brand-primary;
		font-size: 1rem;
		margin-left: 1.5em;
		svg {
			width: 1em;
			height: 1em;
			display: inline-block;
			vertical-align: middle;
			top: -0.1em;
			margin-right: 0.4em;
		}
	}
	&__toggle {
		display: block;
		position: relative;
		z-index: 2;
		margin-left: 2.5em;
		margin-top: 0.5em;
	}
	@include mq(1060) {
		&__phone {
			margin-left: 0;
		}
	}
	@include mq($mq-small) {
		padding: 1.5em;
		&__logo {
			svg {
				width: 102px;
			}
		}
		&__menu {
			left: 0;
			right: 0;
			padding: 0;
			background: none;
			opacity: 0;
			visibility: hidden;
			transform: none;
			transition: all 0s 0.6s;
			&:after {
				bottom: 0;
				left: -50%;
				right: -50%;
				border: 0;
				width: auto;
				height: auto;
				background: $brand-primary;
				transform: skew($angle) scaleX(0);
			}
			&__inner {
				opacity: 0;
				visibility: hidden;

				transform: scale(0.75);
				transition: all $a-slow;
			}
			&.is-open {
				opacity: 1;
				visibility: visible;
				transform: none;
				transition-delay: 0s;

				&:after {
					transform: skew($angle) scaleX(1);
				}
				.top__menu__inner {
					opacity: 1;
					visibility: visible;
					transform: scale(1);
				}
			}
		}
		&__phone {
			@include font-size($s-micro);
			svg {
				margin-right: 0.2em;
			}
		}
	}
	@include mq($mq-xsmall) {
		justify-content: space-between;
		.social-links {
			display: none;
		}
		&__menu {
			.social-links {
				display: block;
			}
		}
		&__toggle {
			margin-left: 1.5em;
		}
	}
}
.site-overlay {
	position: absolute;
	opacity: 0;

}

.is-open .site-overlay {
		display: block;
		position: fixed;
		visibility:visible;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		z-index: 1;
		opacity: 1;
    background-color: rgba(0,0,0,.5);
    transition: all .5s ease;
}


.main-menu {
    position: absolute;
	width: 100%;
	top: 390px;
	z-index: 999;
	margin-top: -85px;

	.menu-header-menu-container {

		// @extend .container;

		.menu {
			display: flex;

			li.menu-item { 
				width: 100%;
					a {
					text-transform: uppercase;
					color: #fff;
					/* font-weight: 600; */
					font-size: 1em;
					text-align: center;
					letter-spacing: 2.5px;
					display: inline-block;
					padding: 30px 10px;
					border-right: 1px solid #fff;
					width: 100%;
					&:hover {
						color: $brand-primary;
					}
					}
			&:last-child {
				a {
					border-right: none;
				}
			}
			}
		}
	}
	

}

.home {
	.main-menu {
	top: 100vh;
	}
}

.archive {
	.main-menu {
	top: 68vh;
	}
}

.sticky {
	position: fixed;
	top: 0!important;
	width: 100%
  }
  

#sticky-header.sticky{
	background: #fff;
	box-shadow: 0px 0px 8px rgba(0,0,0,0.1);
	

	svg {
		fill: $brand-primary;
	}

	.top__menu:not(.is-open) .social-links {
		color: $c-black;
		 a:hover {
			color: $brand-primary;
		}
	}

	.hamburger {
		color: #000;
	}

}  

#sticky-menu.sticky{ 
	margin-top: 0;
	width: auto;
    left: 200px;
    right: 200px;
	a {
		color: #000;
		border: none;
		padding: 36px 10px 20px;
		@include media-breakpoint-down(lg) {
			font-size: 0.9em;
			letter-spacing: 1px;
			line-height: 1em;
		}

		&:hover {
			color: $brand-primary;
		}
	}
}