.related {
	display: flex;
	position: relative;
	margin: 10px -5px;

	@include mq($mq-xsmall) {
		flex-direction: column;
	}


	&__title {
		flex: 1 1 20%;
		background: $c-dark;
		padding: 2em;
		position: relative;
		margin: 0 5px 0 -5px;

		@include mq($mq-xxlarge) {
			flex-basis: 25%;
		}
		@include mq($mq-large) {
			flex-basis: (1/3)*100%;
		}

		@include mq($mq-small) {
			flex-basis: (1/2)*100%;
		}

		h3 {
			@include font-size($s-gamma);
			text-transform: uppercase;
			font-weight: $w-bold;
			color: $c-white;

			@include mq($mq-large) {
				@include font-size($s-delta);
			}

			&:after {
				content: '';
				position: relative;;
				display: block;
				background: $brand-primary;
				height: 10px;
				width: 70px;
				margin-top: 1em;
			}

		}

	}
	&__articles {
		flex: 3 3 80%;
		@include mq($mq-xxlarge) {
			flex-basis: 75%;
		}
		@include mq($mq-large) {
			flex: 2 2 (2/3)*100%;
		}

		@include mq($mq-small) {
			flex-basis: 50%;
		}

		@include mq($mq-xsmall) {
			flex-basis: 100%;
		}
	}

}
