.sector {
	&__insight {
		position: relative;
		overflow: hidden;
		min-height: calc(100vh - 90px);
		display: flex;
		align-items: center;

		@include font-size(62);
		line-height: 1.1;
		font-weight: $w-bold;
		color: $c-white;
		text-align: center;
		&__bg {
			background: $brand-primary;
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
		}
		&__bg-img {
			content: "";
			display: block;
			background: center center no-repeat;
			background-size: cover;
			background-color: inherit;
			background-blend-mode: multiply;
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			z-index: 0;
			opacity: 0.6;
		}
		&__content {
			position: relative;
			z-index: 1;
			padding: 4em 1em;
		}
		@include mq($mq-xxlarge) {
			@include font-size(50);
		}
		@include mq($mq-xlarge) {
			@include font-size(42);
		}
		@include mq($mq-small) {
			@include font-size(30);
		}
		@include mq($mq-xsmall) {
			@include font-size(22);
		}
	}
	&__quote {
		background-color: $c-light;
		position: relative;
		margin: 0px 0 50px;
		@include mq($mq-medium) {
			margin-top: 110px;
		}

	}
	&__content {
		position: relative;
		&:before, &:after {
			content: '';
			position: absolute;
			display: block;
			left: 0;
			right: 0;
			width: 0;
			height: 0;
			border-style: solid;
			border-width: 0 0 200px 100vw;
			border-color: transparent transparent $c-white transparent;
		}
		&:before {
			bottom: 100%;
		}
		&:after {
			top: 100%;
		}
		@include mq($mq-medium) {
			&:before {
				border-width: 0 0 100px 100vw;
			}
		}
	}
	@include mq($mq-large) {
		&__insight {
			&__content {
				padding: 2em 1em;
			}
		}
	}

}

.sector-quote {
	max-width: 1000px;
	margin: 0 auto;
	padding: 20px;
	display: flex;
	align-items: center;


	&__title h3{
		color: #fff;
	}
	&__content {
		flex: 4;
		padding: 2em 2em 1em 0;

		}
	&__author {
		flex: 2;
		@include font-size($s-delta);
		text-align: center;
		position: relative;
		.bubble {
			position: relative;
			margin-left: 5px;
			margin-right: 5px;
			margin-top: -3em;
			margin-bottom: -3em;
			z-index: 10;
			transition: all 0.2s ease;
			top: 0;
			&:hover {
				color: #fff;
				top:-10px;
			}
		}
	}
	@include mq($mq-medium) {
		flex-direction: column;
		&__content {
			margin-bottom: 10px;
		}
		&__author {
			max-width: 320px;
		}
	}
}


.service-card {
	h2 {
		text-transform: uppercase;
		font-weight: bold;
		letter-spacing: 1px;
	}
	.features {
		h3 {
			margin: 5px 0;
			text-align: center;
		}
	}
	.btn-primary {
		width: auto;
    display: inline-block;
    padding: 9px 20px;
    margin: 0 auto;
	}
}
