.wrapper {
	@include clearfix;
	margin: 0 -5px -5px;

	&__item {
		float: left;
		width: 20%;
		padding: 5px;

		@include mq($mq-xxlarge) {
			width: (100% / 4);
		}

		@include mq($mq-large) {
			width: (100% / 3);
		}

		@include mq($mq-small) {
			width: (100% / 2);
		}

		@include mq($mq-xsmall) {
			width: (100% / 1);
		}

	}

	&__title {
		background: $c-dark;
		padding: 2em;
		font-weight: bold;
		text-transform: uppercase;
		@include font-size($s-gamma);
	}

}

.three {
	display: flex;
	&__col {
		flex: 1;
		padding: 0 20px;
		&:first-child {
			padding: 0 30px 0 10px;
			@include mq(560) {
				padding: 0 10px;
			}
		}
		&:last-child {
			padding: 0 10px 0 30px;
			@include mq(560) {
				padding: 0 10px;
			}
		}
	}
	@include mq($mq-medium) {
		flex-wrap: wrap;
		&__col {
			flex: 1 1 50%;
		}
	}
	@include mq(560) {
		flex-direction: column;
	}
}

.mb1 {
	margin-bottom: 1em;
}
.mb2 {
	margin-bottom: 2em;
}
.mb3 {
	margin-bottom: 3em;
}
.mb4 {
	margin-bottom: 4em;
}
.mb5 {
	margin-bottom: 5em;
}

 .wow { visibility: hidden; }

// Home Page


.container-responsive {
  @extend .container-fluid;
  @include media-breakpoint-up(lg) {
      max-width: 960px;
  }
  @include media-breakpoint-up(xl) {
      max-width: 1140px;
  }

}


.relative {
  position: relative;
}

.absolute {
  @include media-breakpoint-up(lg) {
    position: absolute;
  }
}

.divide-right {
    .left-half {
      @include media-breakpoint-up(lg) {
        left: 0;
        right: 50%;
      }
    }

    .right-half {
      @include media-breakpoint-up(lg) {
        right: 0;
        left: 50%;
      }
    }
}

.divide-left {
    .left-half {
      @include media-breakpoint-up(lg) {
        right: 0;
        left: 50%;
      }
    }

    .right-half {
      @include media-breakpoint-up(lg) {
        left: 0;
        right: 50%;
      }
    }
}


.divide-left, .divide-right {
  .sector-image {
		min-height: 200px;
    height: 100%;
    overflow: hidden;
    background-size: cover;
    background-repeat: no-repeat;
  }
  .row {
    @include media-breakpoint-up(md) {
      height: 100%;
    }
  }
}

.divide-right .sector-image {
  background-position: left;
  @include media-breakpoint-up(md) {
    &:before {
      right: auto;
      left: -50px;
    }
  }
}
.divide-left .sector-image {
  background-position: center;
  @include media-breakpoint-up(md) {
    &:before {
      left: auto;
      right: -50px;
      transform: skew(5deg,0);
    }
  }
}

.img-logo {
		width: 130px;
		max-width: 100%;
		max-height: 50px;
    object-fit: contain;
}

p img {
    padding: 0;
    max-width: 100%;
}

.sectionheader {
	overflow: hidden;
	height: auto;
	    position: relative;
}

.pageheader {
	height: 50vh;
	margin-top: -45px;
	min-height: 400px;
	background-position: center;
	    background-size: cover;
	.display-2 {
		color: #fff;
	}
	&:before {
    background-color: rgba(0,0,0,.35);
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: block;
	}
}
