.grid, .first-grid, .second-grid {
  width: 100%;
  margin: 0 auto;
  position: relative;
  @include media-breakpoint-up(md) {
    }

}

.grid-sizer,
.grid-item {
  // margin-bottom: 20px;
  width: 100%;

  @include media-breakpoint-up(md) {
    width: 32%;
  }
}

.grid .grid-sizer,
.grid .grid-item {

  @include media-breakpoint-up(lg) {
    width: 23.5%;
  }
}

.small-isotope .grid-sizer,
.small-isotope .grid-item,
.page-template-careers-template .small-isotope .grid-sizer,
.page-template-careers-template .small-isotope .grid-item {
  // margin-bottom: 20px;
  width: 48%;

  @include media-breakpoint-up(md) {
    width: 32%;
  }
  @include media-breakpoint-up(lg) {
    width: 17%;
  }
}

.large-isotope .grid-sizer,
.large-isotope .grid-item {
  // margin-bottom: 20px;
  width: 48%;

  @include media-breakpoint-up(md) {
    width: 32%;
  }
}

.grid-sizer.large,
.grid-item.large {
  // margin-bottom: 20px;
    width: 100%;
    @include media-breakpoint-up(md) {
      width: 66%;
    }
  @include media-breakpoint-up(lg) {
    width: 49%;
  }
}

.grid-item.featured {
  width: 100%;
  // padding-bottom: 15px;
  @include media-breakpoint-up(md) {
    width: 66%;
  }
}

.grid .grid-item.featured {
  @include media-breakpoint-up(lg) {
    width: 74.5%;
  }
}


.grid-item.tall {
  height: 540px;
}

.grid-item.video {
  width: 100%;
  // padding-bottom: 15px;
  @include media-breakpoint-up(lg) {
    width: 66%;
  }
}

.gutter-sizer {
   width: 4%;
 @include media-breakpoint-up(md) {
   width: 2%;
 }
}


.grid-item .iframe-video {
   position: relative;
   padding-bottom: 56.25%;
   padding-top: 0px; overflow: hidden;

}

.grid-item .iframe-video iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
