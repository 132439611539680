

.video-hero {

  padding: 0;
  position: relative;
}


.video-item{
    position: absolute;
    z-index: 10;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    display: block;
    background-size: cover;
    transition: all 0.5s ease;
    background-position: center;
}

.icon-play-ytb{

    z-index: 20;
}

.embed-responsive-16by9.small {
  margin-top: -30px;
  &:before {
    padding-top: 34%;
    transition: all 1s ease;
  }
}
.embed-responsive-16by9.active {
  &:before {
    padding-top: 56.25%;
  }
}
