.teaser {
  position: relative;
  display: block;

  &--card {
    height: 100%;
    padding: 20px;
    background-size: cover;
    background-position: center;

    .teaser__btn { bottom: 10px; }

    .teaser__title {
      text-align: left;
    }
  }

  &--img {
    .teaser__title {
      position: absolute;
      top: 50%;
      left: 0;
      right: 0;
      width: 100%;
      padding: 0 20px;
      transform: translate3d(0, -50%, 0);
      opacity: 0;
      transition: all 0.3s ease-in-out;
      text-transform: uppercase;
      @include font-size($s-gamma);
    }
  }

  img {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    transition: all 0.3s ease-in-out;
  }

  &__inner {
    position: relative;
    overflow: hidden;
    display: block;
    text-align: center;

    &:after {
      content: '';
      position: relative;
      display: block;
      padding-bottom: 100%;
    }
  }

  &__title {
    text-align: center;
    font-weight: bold;
    color: white;
    text-shadow: 0 0 20px #000;
  }

  &__btn {
    position: absolute;
    bottom: 40px;
    left: 0;
    right: 0;
    margin: auto;
    z-index: 1;
    max-width: 170px;
    @include font-size($s-micro);

    &--relative {
      @include mq($mq-xsmall) {
        position: relative;
        bottom: auto;
        margin-top: 1em;
      }
    }

  }

  &:hover {

    img {
      filter: grayscale(50%);
      transform: scale(1.1);
    }

    .teaser__title {
      opacity: 1;
    }

  }

}
