.header {




  &__main {
    padding: 30px 0;
    color: $brand-primary;
    display: inline-block;
    text-align: center;
    @include font-size($s-beta);

    span {
      font-weight: normal;
    }
  }

  &__caption {
    margin-top: 30px;
    text-align: center;
    color: $brand-primary;
  }

}
