.social-links {
	a {
		display: inline-block;
		vertical-align: middle;
		padding: 0.4em;
		border-radius: 50%;
		transition: all $a;
		color: $c-white;
		background: transparent;
		transition: all 0.2s ease;
		& + a {
			margin-left: 0.5em;
		}
		svg {
			width: 1em;
			height: 1em;
		}
		&:hover {
			color: $c-white;
			transform: scale(1.25);
			
		}
	}
	&__facebook:hover {
		color: $c-facebook !important;
	}
	&__twitter:hover {
		color: $c-twitter !important;
	}
	&__linkedin:hover {
		color: $c-linkedin !important;
	}
	&__instagram:hover {
		color: $c-instagram !important;
	}
}

.bottom .social-links {
	a {
		color: $c-white;
		opacity: 1!important;
		&:hover {
			color: #ccc!important;
		}
	}
}

.top__menu.is-open {
	.social-links a {
		color: #000;
	}
}


.ctf-author-box {
	display: none;
}