

// Fonts
$f:					canada-type-gibson, serif;

$fa-font-path:        "../webfonts";

// Line Height
$lh:				24;

// Sizes
$s:					16;
$s-alpha:			44;
$s-beta:			30;
$s-gamma:			24;
$s-delta:			17;
$s-micro:			13;

// Angle
$angle: -16deg;

// Weights
$w-light:			300;
$w-normal:			400;
$w-semibold:		600;
$w-bold:			700;

// Animation
$a:					0.2s ease-in-out;
$a-slow:			0.6s ease-in-out;

// Media Queries
$mq-xxlarge:		1560;
$mq-xlarge:			1320;
$mq-large:			1140;
$mq-medium:			920;
$mq-small:			640;
$mq-xsmall:			480;

// Colours
$brand-primary:			#E52528;
$brand-primary-lighter:			#c70000;
$brand-primary-darker: #c70900;

$brand-secondary:			#E52528;
$brand-secondary-lighter:			#c70000;
$brand-secondary-darker: #c70900;

$c-black:			#000;
$c-dark:			#4D4D4D;
$c-mid:				#B2B2B2;
$c-light:			#E4E4E4;
$c-white:			#fff;

$c-error:			#c70000;

$c-facebook:		#3b5998;
$c-twitter:			#1da1f2;
$c-linkedin:		#0077b5;
$c-instagram:		#cd486b;


// Slick Slider
$slick-font-path: "./fonts/" !default;
$slick-font-family: "Font Awesome 5 Free";
$slick-loader-path: "./" !default;
$slick-arrow-color: white !default;
$slick-dot-color: black !default;
$slick-dot-color-active: $slick-dot-color !default;
$slick-prev-character: "\f053" !default;
$slick-next-character: "\f054" !default;
$slick-dot-character: "\2022" !default;
$slick-dot-size: 30px;
$slick-opacity-default: 0.8;
$slick-opacity-on-hover: 1 !default;
$slick-opacity-not-active: 0.25 !default;
