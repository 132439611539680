
// General button styles

a.btn, .button {
  border-radius: 4px;
  border: 1px solid $brand-primary;
  color: $brand-primary;
  background-color: transparent;
  white-space: normal;
	word-wrap: break-word;
  transition: all 0.3s ease;
  cursor: pointer;
  overflow: hidden;
  position: relative;
  padding: 10px 25px;
  text-transform: uppercase;
  font-weight: 400;
  font-size: 0.9em;
  letter-spacing: 1.4px;
  z-index: 5;
  text-align: center;

    &:before {
      content: '';
      background: $brand-primary;
      position: absolute;
      top: 0;
      left: -20px;
      right: -20px;
      bottom: 0;
      z-index: -1;
      transform: skew($angle) translate3d(-100%, 0, 0);
      transition: transform 0.2s ease-in-out;
    }

        &:hover {
          border-color: $brand-primary-lighter;
          color: #fff!important;
          background-color: transparent;
          &:before {
            transform: skew($angle) translate3d(0, 0, 0);
          }
       }
       &:not(:disabled):not(.disabled).active:focus, &:not(:disabled):not(.disabled):active:focus, &.focus, &:focus {
          box-shadow: 0 0 4px 1px $brand-primary-lighter;
       }
       &.active, &:active {
         background-color: transparent;
         border-color: $brand-primary-lighter;
       }
       &:not(:disabled):not(.disabled).active, &:not(:disabled):not(.disabled):active,&:not(:disabled):not(.disabled).active:focus, &:not(:disabled):not(.disabled):active:focus {
         background-color: transparent;
         border-color: $brand-primary-lighter;
         color: $brand-primary;

       }
       &.dark {
           background-color: $brand-primary;
           &:hover {
            border-color: $brand-secondary-darker;
          }
          &.active, &:active {
            border-color: $brand-secondary-lighter;
          }
          &:not(:disabled):not(.disabled).active, &:not(:disabled):not(.disabled):active,&:not(:disabled):not(.disabled).active:focus, &:not(:disabled):not(.disabled):active:focus {
            border-color: $brand-secondary-lighter;
          }
       }

}

a.btn-primary {

}

a.btn-secondary {
  border:none;
  span {
    transition: all 0.2s;
  }
  &:hover {
    span {
    padding-left: 20px;
    }
  }
}

a.btn-tertiary {
  background-color: #fff;
  border-color: #fff;
  &:hover {
    background-color: #fff;
    border-color: $brand-secondary-darker;
  }
}

a.btn-dot {
  border-radius: 50%;
  padding: 3px 3px 0px;
  font-size: 15px;
  line-height: 15px;
  &:hover {
    transform: scale(1.1,1.1);
  }
}


// Extra Bootstrap overrides

a.btn-primary.disabled, .btn-primary:disabled, .btn-secondary.disabled, .btn-secondary:disabled, .btn-default:disabled  {
  background-color: #ccc;
  border-color: #000;
  color: #000;
  &:hover {
    color: #000;
  }
}

.btn-small {
    background: transparent;
    text-align: left;
}

.btn-medium {
  width: auto;
  text-align: center;
  padding: 3px 10px 1px;
  font-size: 1em;
}

.btn-large {
  padding: 10px 20px;
  border-radius: 50px;
  text-align: left;
  max-width: 220px;
  border: 2px solid $brand-secondary-darker;
}

.btn-info, .btn-danger, .btn-default ,  .gform_button {
    background-image: none;
    border-color: transparent;
    background: $brand-primary;
    border-radius: 0;
    color: #fff;
    &:focus, &:hover {
      background-color: $brand-primary;
    }
}




a.btn-white {
  background: transparent;
  border: 1px solid #fff;
  color: #fff;
  svg {
    color: #fff;
  }
  &:hover {
    border: 1px solid #fff;
    color: #fff;
  }
}



a.btn-icon {
  padding: 10px;
  span {
    display: inline-block;
    svg {
      width: 24px;
      margin-bottom: -4px;
    margin-right: 4px;
    }
  }
}
