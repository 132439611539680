html {
	font-size: ($s/16)*100%;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	-webkit-text-size-adjust: 100%;
}

body {
	font-family: $f;
	font-weight: 400;
	line-height: ($lh/$s);
}

p {
	margin-bottom: 1em;
}

a {
	text-decoration: none;
	color: inherit;
}

i, em {
	font-style: italic;
}

b, strong {
	font-weight: $w-bold;
}

.text-alpha {
	@include font-size($s-alpha);
	line-height: 1.1;
	@include mq($mq-small) {
		@include font-size($s-beta);
	}
}
.text-beta {
	@include font-size($s-beta);
	line-height: 1.2;
	@include mq($mq-small) {
		@include font-size($s-gamma);
	}
}
.text-gamma {
	@include font-size($s-gamma);
	line-height: 1.3;
	@include mq($mq-small) {
		@include font-size($s-delta);
	}
}
.text-delta {
	@include font-size($s-delta);
	line-height: 1.4;
	@include mq($mq-small) {
		@include font-size($s-micro);
	}
}
.text-wide {
	max-width: 1200px;
	margin: 0 auto;
}
.text-flush {
	margin-bottom: 0;
}
.text-center {
	text-align: center;
	img {
		margin-left: auto;
		margin-right: auto;
	}
}
.text-right {
	text-align: right;
}
.text-italic {
	font-style: italic;
}
.text-uppercase {
	text-transform: uppercase;
}
.text-light {
	font-weight: $w-light;
}
.text-semibold {
	font-weight: $w-semibold;
}
.text-bold {
	font-weight: $w-bold;
}
.text-heading {
	margin: 60px 0 30px;

	&--first {
		margin-top: 0;
	}
}

.text-subtle {
	color: $c-mid;
}

.text {

	h1,
	h2 {
		color: $brand-primary;
	}

	h1 {
		@include font-size($s-alpha);
		line-height: 1.1;
		@include mq($mq-small) {
			@include font-size($s-beta);
		}
	}
	h2 {
		@include font-size($s-beta);
		line-height: 1.2;
		@include mq($mq-small) {
			@include font-size($s-gamma);
		}
	}
	h3 {
		@include font-size($s-gamma);
		line-height: 1.3;
		@include mq($mq-small) {
			@include font-size($s-delta);
		}
	}
	h4 {
		@include font-size($s-delta);
		line-height: 1.4;
		@include mq($mq-small) {
			@include font-size($s-micro);
		}
	}

	p {
		margin-bottom: 2em;
	}

}
