html, body {
  height: 100%;
}


body {
  color: $c-black;
  font-weight: 300;
  font-size: 1rem;
  line-height: 1.5;
  line-height: 2rem;
  overflow-x: hidden;
}

a {
  color: $brand-primary-darker;
  transition: 300ms;
  text-decoration: underline;

  &:hover, &:active {
    text-decoration: none;
    outline: none;
    color: $c-black;
}
  &:visited, &:focus {
    color: $brand-primary;
  }
}
h1, h2, h3, h4, h5, h6 {
  font-weight: 300;
  color: $c-black;
}

h1, .h1 { font-size: 1.75em;
  @include media-breakpoint-up(xl) {
    font-size: 2.2em;
    letter-spacing: 0.2px;
    }
  }

h2, .h2 { font-size: 1.6em;
  @include media-breakpoint-up(xl) {
    font-size: 2em;
    letter-spacing: 0.2px;
  }
}
h3, .h3 { font-size: 1.2em; font-weight: 600; }
h4, .h4 { font-size: 1.2em; font-weight: 600; }
h5, .h5 { font-size: 1.2em; }
h6, .h6 { font-size: 1.1em; }

h2 { margin-top: 2.0rem; margin-bottom: 1rem; }
h3 { margin-top: 1.8rem; margin-bottom: 1rem; }
h4 { margin-top: 1.5rem; margin-bottom: 1rem; }
h5 { margin-top: 1.2rem; margin-bottom: 1rem; }
h6 { margin-top: 1.2rem; margin-bottom: 1rem; }

h2, h3, h4, h5, h6 {
  position: relative;
  .anchor {
    color: #eeeeee;
    position: absolute;
    left: -1.5rem;

    &:hover {
      color: $link-color;
    }
  }
  .placeholder {
    position: absolute;
    top: -6rem;
    visibility: hidden;
  }
}
h2.noanchor, h3.noanchor, h4.noanchor, h5.noanchor, h6.noanchor {
  position: initial;
  .anchor {
    display: none;
  }
  .placeholder {
    display: none;
  }
}

b, strong {
    font-weight: 600;
}

.display-2 {
  font-size: 2.2em;
}
.display-3 {
  font-weight: 600;
  font-size: 1em;
}
.display-4 {
  font-size: 2em;
}

hr {
  max-width: 60px;
  border-top: 3px solid $brand-primary;
}

.red {
  color: $brand-primary;
}

.lightred-bg {
  background-color: $brand-primary;
}

.red-bg {
  background-color: $brand-primary-darker;
}

.red-bg, .lightred-bg {
  color: #fff;
  h1, h2, h3, h4, h5, h6, p, a {
    color: #fff;
  }
  a:hover {
    color: #ccc;
  }
}

.grey {
  background-color: $c-light;
}

p {
  margin-bottom: 2rem;
}
.feature-large p {
  font-size: 1.4em;
  font-weight: 200;
  max-width: 900px;
  margin: 0 auto 30px;
}

.feature p {
  font-size: 1.1em;
  max-width: 900px;
  margin: 0 auto 30px;
}

.small {
  font-weight: 200;
}

.on-top {
  z-index: 10;
}

.on-bottom {
  z-index: -1;
}

.minus-top {
    margin-top: -300px;
    margin-bottom: 300px;
}

.large-pad {
  padding: 4em;
}

.white-text {
  color: #fff!important;
}

span.uppercase {
  text-transform: uppercase;
}

span.italic {
  font-style: italic;
}

.content ul.wp-block-gallery {
  padding: 20px 0;
}

// Admin bar -------
#wpadminbar {
  position: fixed;
  bottom: 0;
  top: initial;
}
