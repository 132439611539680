// Media Queries
@mixin mq($mw, $dir: 'width') {
	@media screen and (max-#{$dir}: #{$mw} + px) {
		@content;
	}
}

// Font size
@mixin font-size($px) {
	font-size: $px + px;
	font-size: ($px / $s) + rem;
}

// Tracking
@mixin tracking($ps) {
	letter-spacing: ($ps / 1000) + em;
}

// Clearfix
@mixin clearfix() {
	&:after {
		content: "";
		display: table;
		clear: both;
	}
}
