.skills {

	&__icon {
		height: auto;
		min-height: 70px;
		align-items: center;
		img {
			height: 60px;
			width: auto;
		}
	}

}


.service-repeater {

	h3 {
		color: $brand-primary;
		text-transform: uppercase;
		letter-spacing: 1px;
		font-weight: 400;
	}

	text-align: center;
	@include media-breakpoint-up(md) {
		text-align: right;

		.service-img {
			text-align: left;
			padding-left: 8em;
		}


			&:nth-child(odd) {
				.row {
				flex-direction: row-reverse;
				text-align: left;

				.service-img {
					text-align: right;
					padding-left: 0;
					padding-right: 8em

					}
				}
			}


		}

}