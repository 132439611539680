.content {

  @include clearfix;
  position: relative;

  &__region {
    max-width: 800px;
    margin: 0 auto;
  }

  &__image {
    position: relative;
    overflow: hidden;
  //  margin-bottom: 10px;

    &:after {
    //  content: '';
      position: absolute;
      display: block;
      left: 0;
      right: 0;
      bottom: 0;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0 0 20px 100vw;
      border-color: transparent transparent white transparent;

      @include mq(768) {
        border-width: 0 0 30px 100vw;
      }
    }

    &--angle-top {
      &:after {
        top: 0;
        bottom: auto;
        border-width: 200px 100vw 0 0;
        border-color: white transparent transparent transparent;

        @include mq(768) {
          border-width: 100px 100vw 0 0;
        }
      }
    }

    &__header {
      position: absolute;
          left: 0;
          right: 0;
          top: 0;
          bottom: 0;
          height: 100%;
          width: 100%;
          text-align: center;
          /* margin-top: -100px; */
          /* transform: translateY(-50%); */
          color: #fff;
          text-shadow: 5px -5px 10px rgba(0,0,0,.5), -5px 5px 10px rgba(0,0,0,.5);

        h1 {
          font-size: 1.6em;
          line-height: 1.25em;
          color: #fff;
          max-width: 800px;
          margin: 0 auto;
          padding: 0 10px;
          display: flex;
          align-items: center;
          justify-content: center;
          height: 100%;
          @include media-breakpoint-up(md) {
          font-size: 3em
          }

          span {
            @include font-size($s-gamma);
          }
        }

        p {
          font-weight: normal;
          text-transform: uppercase;
    }

  }
}

  ul {
    list-style: disc outside;
    padding-left: 40px;

    li {
      color: black;
      margin-bottom: 20px;
    }
  }


}


// Media alignment
.alignnone {
  margin-left: 0;
  margin-right: 0;
  max-width: 100%;
  height: auto;
}
.aligncenter {
  display: block;
  margin: ($spacer / 2) auto;
  height: auto;
}
.alignleft,
.alignright {
  margin-bottom: ($spacer / 2);
  height: auto;
}
@include media-breakpoint-up(sm) {
  // Only float if not on an extra small device
  .alignleft {
    float: left;
    margin-right: ($spacer / 2);
  }
  .alignright {
    float: right;
    margin-left: ($spacer / 2);
  }
}

// Captions
.wp-caption {
  @extend .figure;
}
.wp-caption > img {
  @extend .figure-img;
  @extend .img-fluid;
}
.wp-caption-text {
  @extend .figure-caption;
}
.wp-caption-text {
  @extend .figure-caption;
}

// Text meant only for screen readers
.screen-reader-text {
  @extend .sr-only;
  @extend .sr-only-focusable;
}
