.share {
  display: table;
  width: 100%;
  margin: 3em 0;

  &__section {
    display: table-cell;
    vertical-align: middle;

    &--share {
      text-align: center;
      width: 200px;
    }

    &--next {
      text-align: right;
    }

    &--prev,
    &--next {
      width: 100px;

      a:hover {
        color: $brand-primary;
      }
    }

  }
}
