.feed {
	display: flex;
	margin: 0 -5px;
	&__col {
		flex: 1 1 20%;
		padding: 0 5px;
	}
	&__twitter {
		flex-basis: 40%;
		display: flex;
	}
	@include mq($mq-xxlarge) {
		&__col {
			flex-basis: 25%;
		}
		&__twitter {
			display: block;
		}
	}
	@include mq($mq-large) {
		flex-wrap: wrap;
		&__col {
			flex-basis: (1/3)*100%;
		}
		&__twitter {
			flex-basis: 100%;
			display: flex;
			margin: 0 -5px 10px;
		}
	}

	@include mq(760) {
		&__col {
			flex-basis: 50%;
			margin: 0 0 10px;
		}
		&__twitter {
			display: block;
		}
	}
	@include mq($mq-small) {
		&__col {
			flex-basis: 100%;
		}
	}
}

h6.twitter-heading {
	margin: 0 0 0.2em 0;
    padding: 0.4em 0;
		font-size: 0.96em;
		a {
    font-weight: 600;
	}
		&:after {
			content:"";
			display: block;
			border: 1px solid #fff;
			width: 60px;
			margin-top: 5px;
		}

}

.twitter-feed {
	background: $c-mid;
    overflow: hidden;
	padding: 1em;
	position: relative;
	flex: 1;
	border: 0 solid $c-white;
	& + & {
		border-width: 0 0 0 10px;
	}
	@include mq($mq-xxlarge) {
		& + & {
			border-width: 10px 0 0 0;
		}
	}
	@include mq($mq-large) {
		& + & {
			border-width: 0 0 0 10px;
		}
	}
	@include mq(760) {
		border: 0;
		& + & {
			border-top: 10px solid $c-white;
		}
	}
	&__header {
		text-align: center;
		font-weight: bold;
		color: $c-white;
		margin-bottom: 20px;
	}
	&:before {
		content: '';
		position: absolute;
		bottom: 10px;
		right: 10px;
		display: block;
		width: 100px;
		height: 81px;
		background-color: rgba($c-white, 0.3);
		mask-image: url('dist/img/twitter.svg');
		background-repeat: no-repeat;
		background-size: contain;
	}
	.tweets {
		position: relative;
		z-index: 1;
	}
	.tweet {
		a {
			font-weight: $w-bold;
		}
	}
	.tweet_text {
		margin-bottom: 0.5em;
	}
	.tweet_secondary_meta {
		@include font-size($s-micro);
	}
	.tweet_retweet {
		font-style: italic;
		&:before {
			content: ' - ';
		}
	}
	.tweet_intents {
		margin-top: 1em;
		@include font-size($s-micro);
	}
	.tweet_intent {
		display: inline-block;
		margin-right: 1em;

		&:hover {
			transition: $a;
			color: $c-white;
		}
	}
}
