.signup {

  background: $c-dark;
  margin-bottom: 10px;
  display: table;
  width: 100%;

  @include mq($mq-small) {
    display: block;
  }

  &__caption {
    display: table-cell;
    vertical-align: middle;
    text-align: right;
    background: $brand-primary;
    @include font-size($s-gamma);
    text-transform: uppercase;
    font-weight: $w-bold;
    color: $c-white;
    position: relative;
    z-index: 1;
    transition: transform $a;
    padding-left: 10px;

    @include mq($mq-medium) {
      @include font-size($s-delta);
    }

    @include mq($mq-small) {
      padding: 10px;
      text-align: center;
      display: block;
    }

    &:after {
			content: "";
			display: block;
			background: $brand-primary;
      border-right: 10px solid white;
			position: absolute;
			top: 0;
			left: 0;
			right: -4.5rem;
			bottom: 0;
			z-index: -1;
			transform: skew($angle);

      @include mq($mq-medium) {
        right: -3.5rem;
      }

      @include mq($mq-small) {
        display: none;
      }
		}

  }

  &__form {
    display: table-cell;
    padding-left: 9em;
    padding-right: 10px;

    @include mq($mq-medium) {
      padding-left: 7em;
    }

    @include mq($mq-small) {
      padding-left: 10px;
    }
  }

  &__table {
    display: table;
    width: 100%;
    padding: 1.5em 0;

    @include mq($mq-large) {
      padding: 0.75em 0;
    }

    @include mq($mq-xsmall) {
      display: block;
    }
  }

  &__cell {
    display: table-cell;
    vertical-align: middle;
    padding: 0 10px;

    @include mq($mq-xsmall) {
      display: block;
      padding: 5px;
      text-align: center;
    }

    label {
      margin-bottom: 0;
    }

    .wpcf7-list-item {
      color: $c-white;
      @include font-size($s-micro);

    }

  }

}
