#breadcrumbs {
    color: #000;
    text-transform: uppercase;
    padding: 0 30px;
    font-size: .6em;
    width: 100%;
    background: #fff;

    @include media-breakpoint-up(md) {
      font-size: 0.75em;
      letter-spacing: 1px;
    }
  a {
    color: #000;
    display: inline-block;
    padding: 10px 25px 10px 7px;
    margin: 0;
    position: relative;
    &:after {
      content: '';
      position: absolute;
      top: 10px;
      right: 4px;
      display: block;
      font-family: 'FontAwesome';
      margin: 0 4px 0 9px;
      transform: scale(0.8);
    }
    &:hover {
      background-color: $brand-primary;
      color: #fff;
      &:after {
        transform: rotateY(180deg);
        transform-origin: 40%;
        transition-property: transform;
        transition-duration: 0.6s;
        transition-timing-function: ease-out;
      }
    }


  }
  .breadcrumb_last {
    margin-left: 5px;
  }
}
