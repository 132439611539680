.card {
  border: none;
  border-radius: 0;
  overflow: hidden;
  font-size: 0.8em;
  line-height: 1.4em;
  background-size: cover;
  min-height: 250px;

  &.overlay:before {
    content: "";
    background-color: #000;
    opacity: 0.6;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
  }

  @include media-breakpoint-up(md) {
    font-size: 0.65em;
    line-height: 1.3em;
    min-height: 230px;
  }
  @include media-breakpoint-up(md) {
    min-height: 260px;
  }


  .card-img {
    display: none;
  }
  .card-body {
    padding: 10px;

    .readmore {


      span.dots {
        display: block;
      }
      span.rm {
        display: none;
      }
    @include media-breakpoint-down(sm) {

        margin: 0 20px;
        text-align: center;
        font-size: 0.9em;
        &:hover {
          background-color: $brand-primary;
        }
        span.dots {
          display: none;
        }
        span.rm {
          display: block;
        }
      }
    }
  }
  .card-title, .card-title a {
    font-size: 1em;
    line-height: 1.4em;
    color: #fff;
    text-align: left;
    margin: 5px 0;
    font-weight: 400;
      @include media-breakpoint-up(sm) {
        font-size: 1.4em;
      }
  }
  .meta, .meta a {
    margin: 0;
    font-size: 0.9em;
  }
  p {
    margin: 6px 0;
    font-size: 1.2em;
    color: #fff;
    @include media-breakpoint-up(sm) {
      font-size: 1.4em;
    }
    span {
      text-transform: uppercase;
      font-size: 1.3em;
    }
  }

}

.featured-posts {
  .card-title, .card-title a {
    text-transform: uppercase;
    font-weight: 600;
    text-align: center;
      @include media-breakpoint-up(sm) {
        font-size: 1.8em;
      }
  }

  .card {
    min-height: 350px;
  }

}

.card.quote {
       p {
        margin: 6px 0;
        font-size: 1.4em;
        color: #fff;
        line-height: 1.8em;
        @include media-breakpoint-up(sm) {
          font-size: 1.6em;
        }
      }
    span p {
      font-size: 1em;
      font-family: 'Gibson';
      text-transform: uppercase;
      margin: 5px 0 0 0;
    }
  }


/*

.card {
  position: relative;
  display: table;
  overflow: hidden;
  width: 100%;
  height: 100%;
  background: $c-dark;
  background-size: cover;
  background-position: center;

  &:hover {
    .card__hover {
      opacity: 1;
      transform: translate3d(0, 0, 0);
    }

    .card__divider:after {
      width: 100%;
    }
  }

  &__height {
    width: 1%;
    display: table-cell;
    padding-bottom: 100%;
  }

  &__hover {
    display: table-cell;
    vertical-align: middle;
    width: 98%;
    opacity: 0;
    padding: 10px;
    transform: translate3d(-100%, 0, 0);
    transition: 0.4s ease-in-out;
    color: $c-white;

    &:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      display: block;
      background: $brand-primary;
      transform: scale(1.5);
    }
  }

  &__heading {
	display: table-cell;
	width: 98%;
	vertical-align: middle;
    color: $c-white;
    padding: 0 2em;
    font-weight: bold;
    @include font-size($s-gamma);
    text-transform: uppercase;

    p {
      font-weight: normal;
    }

    @include mq(960) {
      padding: 0 10px;
    }

  }

  &__title {
    font-weight: bold;
  }

  &__content {
    position: relative;
    z-index: 1;
  }

  &__preview {
    max-height: 7.5em;
    overflow: hidden;
    margin-bottom: 10px;
    @include font-size($s-micro);

    @include mq(1200) {
      max-height: 6em;
    }

    @include mq(960) {
      max-height: 4.5em;
    }

    &--tall {
      max-height: none;
    }
  }

  &__divider {
    position: relative;
    display: block;
    height: 9px;
    margin: 10px 0;

    &:before {
      content: '';
      display: block;
      width: 90px;
      background: white;
      height: 9px;
    }

    &:after {
      content: '';
      display: block;
      width: 90px;
      background: white;
      margin-top: -4px;
      height: 1px;
      transition: 0.25s ease-in-out 0.42s;
    }
  }

  &__nav {
    margin-top: 10px;
    a {
      display: block;
      margin-bottom: 10px;
      text-align: left;
    }
  }


}

*/

.bio-link {
  opacity: 0;
}
.card:hover .bio-link {
  opacity: 1;
}

.post-type-archive-member .card {
  @include media-breakpoint-up(md) {
    font-size: 0.65em;
    line-height: 1.3em;
    min-height: 230px;
  }
  @include media-breakpoint-up(md) {
    min-height: 230px;
  }
}

.border-red {
  border: 1px solid $brand-primary;
}


.member-card {
    .image {
      height: 185px;
      background-size: cover;
    }
    color: #000;

      h5 {
        font-weight: 400;
        font-size: 1.4em;
      }

      a {
        color: $brand-primary;
        font-size: 1.2em;
      }

      span {
        text-transform: uppercase;
        font-weight: 200;
        letter-spacing: 2px;
  
      }
}