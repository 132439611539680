.job-listing {
  background-color: $c-light;
  &.card {
    min-height: 220px;
    .card-title, .card-title a {
      color: #000;
      }
      .btn {
        color: #000;
        border-color: #000;

      }
    p {
      color: #000;
    }
    p {
      font-size: 1.3em;
    line-height: 1.6em;
    }
    a {
      font-size: 1em;
    }
  }
  &.highlight {
    background: $brand-primary;
    &.card .card-title, &.card  .card-title a, p {
      color: #fff;

    }
    .btn {
        border-color: #fff;
        color: #fff;
        &:hover {
          color: #000!important;
        &:before {
          background: #fff;
        }
      }
    }
  }
}
