$quoteHeight: 1.5em;
$quoteWidth: $quoteHeight * 1.5;

.bubble {
	display: block;
	padding: 1.5em;
	border-radius: 0.5em;
	background: $c-dark;
	color: $c-white;
	position: relative;
	margin-bottom: $quoteHeight;
	@include mq($mq-small) {
		padding: 1em;
	}
	&--square {
		border-radius: 0;
	}
	&--slim {
		padding: 1em;
	}
	&--chunky {
		font-size: 2rem;
		border-radius: 1.25em;
	}
	&--brand {
		background: $brand-primary-lighter;
		&:after {

		}
	}
	&:after {
		content: "";
		display: block;
		position: absolute;
		width: 0;
		height: 0;
		border-style: solid;
	}
	&--top-left:after {
		bottom: 100%;
		left: $quoteWidth;
		transform: skew(-$angle);
		border-width: $quoteHeight 0 0 $quoteWidth;
		border-color: transparent transparent transparent $c-dark;
	}
	&--top-left.bubble--brand:after {
		border-color: transparent transparent transparent $brand-primary;
	}
	&--top-right:after {
		bottom: 100%;
		right: $quoteWidth;
		transform: skew($angle);
		border-width: 0 0 $quoteHeight $quoteWidth;
		border-color: transparent transparent $c-dark transparent;
	}
	&--top-right.bubble--brand:after {
		border-color: transparent transparent $brand-primary transparent;
	}
	&--bottom-left:after {
		top: 100%;
		left: $quoteWidth;
		transform: skew(-$angle);
		border-width: 0 $quoteWidth $quoteHeight 0;
		border-color: transparent $c-dark transparent transparent;
	}
	&--bottom-left.bubble--brand:after {
		border-color: transparent $brand-primary transparent transparent;
	}
	&--bottom-right:after {
		top: 100%;
		right: $quoteWidth;
		transform: skew($angle);
		border-width: $quoteHeight $quoteWidth 0 0;
		border-color: $c-dark transparent transparent transparent;
	}
	&--bottom-right.bubble--brand:after {
		border-color: $brand-primary-lighter transparent transparent transparent;
	}
}
